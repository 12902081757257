<script setup lang="ts">
import { ref } from 'vue'

// const props = defineProps({
//   exceptions: {
//     type: Array<string>,
//     default: () => [],
//   },
// })

// const siteAccess = ref(false)

// const { getAuthUser } = useVoixUserAuth()
// const { canAccessSite } = useVoixSiteAuth()
// const route = useRoute()

// await getAuthUser().then(() => {
//   canAccessSite(route, props.exceptions).then(() => {
//     siteAccess.value = true
//   })
// })

const siteAccess = ref(true)
</script>

<template>
  <div>
    <template v-if="siteAccess">
      <slot />
    </template>
  </div>
</template>
